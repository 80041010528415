import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "~/components/elements/Button";
import InputCheckbox from "~/components/elements/InputCheckbox";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { AgentContext } from "~/contexts/agent-context";
import { DateTime } from "luxon";
import { delay } from "lodash";
import AgentChat from "./AgentChat";
import FolderPickerField from "~/components/fields/FolderPickerField";
import Input from "~/components/elements/Input";
import { UserContext } from "~/contexts/user-context";
import Notice from "../shared/Notice";
import { ContactsContext } from "../../contexts/contacts-context";

export default function AgentSettings() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { contactProperties } = useContext(ContactsContext);
  const { agent: initialAgent, updateAgent } = useContext(AgentContext);

  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState(initialAgent || {});

  const { options } = agent;

  const hasChanged = useMemo(
    () => agent !== initialAgent,
    [agent, initialAgent],
  );

  const handleChangeOption = (key, value) =>
    setAgent({ ...agent, options: { ...options, [key]: value } });

  const handleSave = async () => {
    setLoading(true);
    delay(async () => {
      const res = await updateAgent(agent);
      setAgent(res);
      setLoading(false);
    }, 2000);
  };

  useEffect(() => initialAgent && setAgent(initialAgent), [initialAgent]);

  return (
    <div className="flex flex-col-reverse md:flex-row gap-6 h-full">
      <div className="flex-grow flex flex-col overflow-auto space-y-6">
        <div className="bg-yellow-100 text-yellow-800 flex items-center space-x-2 rounded-lg p-3">
          <QuestionMarkCircleIcon className="h-5" />
          <div className="text-sm">{t("agent.settings.description")}</div>
        </div>
        {/* Settings */}
        <div className="flex flex-col lg:grid grid-cols-2 2xl:grid-cols-3 gap-4">
          <div className="space-y-4">
            {/* Reply Delay */}
            <div className="border rounded-lg p-4 space-y-2">
              <InputCheckbox
                value={options?.reply_delay_active}
                onClick={(value) =>
                  handleChangeOption("reply_delay_active", value)
                }
                label={t("agent.settings.reply_delay.title")}
                labelClassName={"text-sm font-medium"}
              />
              <div className="flex flex-col items-start space-y-2">
                <div className="text-sm">
                  {t("agent.settings.reply_delay.label")}
                </div>
                <div className="relative rounded-md shadow-sm">
                  <input
                    type="text"
                    name="price"
                    id="price"
                    className="block w-40 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={options?.reply_delay_value}
                    onChange={(evt) =>
                      handleChangeOption("reply_delay_value", evt.target.value)
                    }
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <select
                      className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                      value={options?.reply_delay_unit}
                      onChange={(evt) =>
                        handleChangeOption("reply_delay_unit", evt.target.value)
                      }
                    >
                      {["seconds", "minutes"].map((unit) => (
                        <option key={unit} value={unit}>
                          {t("time." + unit)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <Notice text={t("agent.settings.reply_delay.notice")} />
              </div>
            </div>
            {/* Personality */}
            <div className="border rounded-lg p-4 space-y-4">
              {/* Agent Role */}
              <div className="space-y-2">
                <div className="block text-sm font-semibold">
                  {t("agent.settings.role.title")}
                </div>
                <Input
                  value={options?.role}
                  onChange={(role) => handleChangeOption("role", role)}
                  placeholder={t("agent.settings.role.placeholder")}
                />
                <Notice text={t("agent.settings.role.notice")} />
              </div>
              {/* Tone of voice */}
              <div className="space-y-2">
                <div className="block text-sm font-semibold">
                  {t("agent.settings.message_length.title")}
                </div>
                <div className="space-y-2">
                  {[10, 25, 50].map((message_length) => (
                    <InputCheckbox
                      value={options?.message_length === message_length}
                      onClick={() =>
                        handleChangeOption("message_length", message_length)
                      }
                      label={t(
                        "agent.settings.message_length." + message_length,
                      )}
                      key={message_length}
                    />
                  ))}
                </div>
              </div>
              {/* Additional options */}
              <div className="space-y-2">
                <div className="block text-sm font-semibold">
                  {t("agent.settings.tone_of_voice")}
                </div>
                <div className="space-y-2">
                  {["upbeat", "neutral", "formal"].map((tone_of_voice) => (
                    <InputCheckbox
                      value={options?.tone_of_voice === tone_of_voice}
                      onClick={() =>
                        handleChangeOption("tone_of_voice", tone_of_voice)
                      }
                      label={t("agent.settings." + tone_of_voice)}
                      key={tone_of_voice}
                    />
                  ))}
                </div>
              </div>
              <div className="space-y-2">
                <div className="block text-sm font-semibold">
                  {t("agent.settings.additional_options")}
                </div>
                <div className="space-y-2">
                  {["use_emojis", "auto_like"].map((setting) => (
                    <InputCheckbox
                      className="p-1"
                      value={!!options?.[setting]}
                      onClick={() =>
                        handleChangeOption(setting, !options[setting])
                      }
                      label={t("agent.settings." + setting)}
                      key={setting}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 space-y-4">
            {/* No Reply */}
            <div className="border rounded-lg p-4 space-y-4">
              <InputCheckbox
                value={options?.no_reply_active}
                onClick={(value) =>
                  handleChangeOption("no_reply_active", value)
                }
                label={t("agent.settings.no_reply.title")}
                labelClassName={"text-sm font-medium"}
              />

              <Notice text={t("agent.settings.no_reply.notice")} />

              <div className="text-sm font-medium">
                {t("agent.settings.no_reply.subtitle")}
              </div>

              <div className="flex items-center space-x-2">
                <div className="text-sm">
                  {t("agent.settings.no_reply.add_to_folder")}
                </div>
                <FolderPickerField
                  value={options?.no_reply_folder_id}
                  onChange={(folderId) =>
                    handleChangeOption("no_reply_folder_id", folderId)
                  }
                />
              </div>

              <div className="flex items-center space-x-2">
                <InputCheckbox
                  value={options?.no_reply_send_email}
                  onClick={(value) =>
                    handleChangeOption("no_reply_send_email", value)
                  }
                  label={t("agent.settings.no_reply.send_email")}
                />
                <Input
                  value={agent?.no_reply_email_address}
                  onChange={(email) =>
                    handleChangeOption("no_reply_email_address", email)
                  }
                />
              </div>

              <div className="space-y-2">
                <div className="text-sm">
                  {t("agent.settings.no_reply.default_message_title")}
                </div>
                <Input
                  type="textarea"
                  className="w-64"
                  rows={4}
                  value={options?.no_reply_message}
                  onChange={(value) =>
                    handleChangeOption("no_reply_message", value)
                  }
                  placeholder={t(
                    "agent.settings.no_reply.default_message_placeholder",
                  )}
                />
              </div>
            </div>
            {/* Request Human */}
            <div className="border rounded-lg p-4 space-y-4">
              <div className="text-sm font-medium">
                {t("agent.settings.request_human.title")}
              </div>

              <div className="flex items-center space-x-2">
                <div className="text-sm">
                  {t("agent.settings.request_human.add_to_folder")}
                </div>
                <FolderPickerField
                  value={options?.request_human_folder_id}
                  onChange={(folderId) =>
                    handleChangeOption("request_human_folder_id", folderId)
                  }
                />
              </div>

              <div className="flex items-center space-x-2">
                <InputCheckbox
                  value={options?.request_human_send_email}
                  onClick={(value) =>
                    handleChangeOption("request_human_send_email", value)
                  }
                  label={t("agent.settings.request_human.send_email")}
                />
                <Input
                  value={options?.request_human_email_address}
                  onChange={(email) =>
                    handleChangeOption("request_human_email_address", email)
                  }
                />
              </div>

              <div className="space-y-2">
                <div className="text-sm">
                  {t("agent.settings.request_human.message_title")}
                </div>
                <Input
                  type="textarea"
                  className="w-64"
                  rows={4}
                  value={options?.request_human_message}
                  onChange={(value) =>
                    handleChangeOption("request_human_message", value)
                  }
                  placeholder={t(
                    "agent.settings.request_human.message_placeholder",
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            {/* Contact properties */}
            <div className="border rounded-lg p-4 space-y-4">
              <div className="text-sm font-medium">
                {t("agent.settings.contact_properties.title")}
              </div>

              <Notice text={t("agent.settings.contact_properties.notice")} />

              {["name", "username", "bio"].map((property) => (
                <InputCheckbox
                  value={options?.contact_properties?.includes(property)}
                  label={t("contacts.columns." + property)}
                  key={property}
                  onClick={(value) =>
                    handleChangeOption(
                      "contact_properties",
                      value
                        ? [...(options?.contact_properties || []), property]
                        : (options?.contact_properties || [])?.filter(
                            (p) => p != property,
                          ),
                    )
                  }
                />
              ))}

              {contactProperties.map((property) => (
                <InputCheckbox
                  key={property.id}
                  value={options?.contact_properties?.includes(
                    `property_${property.id}`,
                  )}
                  label={property.title}
                  onClick={(value) =>
                    handleChangeOption(
                      "contact_properties",
                      value
                        ? [
                            ...(options?.contact_properties || []),
                            `property_${property.id}`,
                          ]
                        : (options?.contact_properties || [])?.filter(
                            (p) => p != `property_${property.id}`,
                          ),
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-72 flex-shrink-0 flex-grow-0 flex flex-col space-y-4">
        <Button
          style="primary"
          label={t("agent.settings.update_agent")}
          loading={loading}
          disabled={!hasChanged}
          onClick={handleSave}
        />
        {hasChanged && (
          <Button
            label={t("shared.cancel_changes")}
            onClick={() => setAgent(initialAgent)}
          />
        )}
        <div className="text-center py-2 text-sm text-dark-gray">
          {t("shared.last_updated", {
            date: DateTime.fromISO(agent?.updated_at).toLocaleString(
              DateTime.DATETIME_MED,
            ),
          })}
        </div>
        <AgentChat training={loading} />
      </div>
    </div>
  );
}
